const diaporamas=[

    {
        label: 'Alexi Mosset, picture:fistelemark',
        imgPath: process.env.PUBLIC_URL + 'images/alexi.jpg',
    },
    {
        label: 'Amélie Wenger-Reymond, picture:fistelemark',
        imgPath: process.env.PUBLIC_URL + 'images/amelie.jpg',
    },


    {
        label: 'Bastien Dayer, picture:360dsm',
        imgPath: process.env.PUBLIC_URL + 'images/bastien.jpg',
    },
    {
        label: 'Marie-Julie Huber, picture:fistelemark',
        imgPath: process.env.PUBLIC_URL + 'images/mariejulie.jpg',
    },

    {
        label: 'Nicolas Michel, picture:360dsm',
        imgPath: process.env.PUBLIC_URL + 'images/nico.jpg',
    },
    {
        label: 'Lea Lathion, picture:swiss-ski',
        imgPath: process.env.PUBLIC_URL + 'images/lea.jpg',
    },

    {
        label: 'Romain Beney, picture:360dsm',
        imgPath: process.env.PUBLIC_URL + 'images/romain.jpg',
    },
    {
        label: 'Maxime Mosset, picture:fistelemark',
        imgPath: process.env.PUBLIC_URL + 'images/maxime/maxime6.jpeg',
    },


    ]

export default diaporamas;
