const races =[




    {
        title: 'FIS Telemark Worldcup Finals 2025',
        text: ' ',
        text2:'',
        text3:'Thyon accueillera les meilleurs athlètes pour clore la saison 2024-2025 :',
        text4:'',
        text5:'',
        text6:'',
        text7: ' ',
        img: process.env.PUBLIC_URL + 'images/fis2024/affiche.png',
        category:'All',

    }


];

export default races;