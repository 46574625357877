import {Card, CardMedia, CardContent, Typography, Button} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";


interface content {
img:string;
title:string;
text:string;
text2:string;
text3:string;
text4:string;
text5:string;
text6:string;
text7:string;
}




function ImageRace(activities:content) {


    const[checked,setChecked]=useState(true);
    useEffect(() => {
        setInterval(() => {
            setChecked(false);
        }, 25000);
    }, []);

    return (
        <Card  sx={{width:{sm:"100vh",lg:"90vh",xl:"75vh"}}} >
<Box
    sx={{display:"flex",
    justifyContent:"center",
    alignItems:"center",

   }}
>

</Box>
            <CardContent>
                <Typography fontSize="1.2rem" color="text.primary">

                    {activities.title}

                </Typography>
            </CardContent>


                <CardContent >
                    <Typography paragraph></Typography>
                    <Typography paragraph fontSize="1.1rem" color="text.secondary">
                       { activities.text}
                    </Typography>

                    {activities.text2 ?
                    <Typography paragraph fontSize="1.1rem" color="text.secondary">
                        {activities.text2}

                    </Typography>
                   :null }
                    {activities.text3 ?

                        <Typography paragraph fontSize="1.1rem" color="text.secondary">
                          { activities.text3}


                        </Typography>

                        :null }
                    {activities.text4 ?

                        <Typography paragraph fontSize="1.1rem" color="text.secondary">
                            { activities.text4}

                        </Typography>

                        :null }
                    {activities.text5 ?

                        <Typography paragraph fontSize="1.1rem" color="text.secondary">
                            { activities.text5}

                        </Typography>

                        :null }
                    {activities.text6 ?

                        <Typography paragraph fontSize="1.1rem" color="text.secondary">
                            { activities.text6}

                        </Typography>

                        :null }




                    {activities.text7 ?

                        <Typography paragraph fontSize="1.1rem" color="text.secondary">
                            { activities.text7}

                        </Typography>

                        :null }

                    <CardMedia
                        component="img"
                        width="100%"
                        height="auto"
                        image={activities.img}
                        alt={activities.img}
                    />


                </CardContent>






        </Card>

    );
};

export default ImageRace;
